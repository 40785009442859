import {
    createStyles,
    Title,
    Text,
    Container,
    Button,
    Center,
    Mark,
    Rating,
    Image,
    Group,
    Stack,
} from '@mantine/core';

import ReactRotatingText from 'react-rotating-text';
import React, {useState, useEffect} from 'react';
import { IconArrowRight } from '@tabler/icons';

import colors from '../../../const/colors';

const useStyles = createStyles((theme) => ({
    wrapper: {
        margin:"auto",
    },

    hero: {
        position: 'relative',
        zIndex: 1,
    },

    title: {
        textAlign: 'center',
        fontWeight: 700,
        fontSize: "4.2em",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        marginBottom: theme.spacing.sm,
        marginTop: theme.spacing.md,
        fontFamily: 'Barlow',

        [theme.fn.smallerThan('lg')]: {
            fontSize: "3em",
        },
    },

    description: {
        color: colors.PrimaryText,
        textAlign: 'center',
        lineHeight: 1.7,
        fontSize: 30,
        maxWidth: "900px",
        fontFamily: 'Barlow',

    },

    subDescription: {
        marginTop: "10px",
        textAlign: 'center',
        fontSize: 24,
    },

    ratingBase: {
        textAlign: 'center',
        fontSize: 18,
        maxWidth: "300px",
        fontWeight: "400",
        marginTop: "-10px",
        color: colors.Primary,
        fontFamily: 'Barlow',

    },

    hiddenMobile: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    hiddenSM: {
        [theme.fn.smallerThan('sm')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },
    highlight: {
        textDecoration: 'underlined'
    }

}));

const Hero = () => {

    const { classes } = useStyles();

    return (
        <Container className={classes.wrapper} size={1200}>
            <div className={classes.hero}>

                <Title className={classes.title}>
                Low-Code
                    <Text component="span"
                        className={classes.highlight}
                        inherit
                        variant="gradient"
                        gradient={{ from: colors.Secondary, to: colors.Secondary }}
                    >
                          {' '}<ReactRotatingText pause={2000} items={['Automation',  'Assistant', 'Bot']} /></Text>
                          <br/>Development Platform.
                    
                </Title>
                <Container pt={10} size={1100} className={classes.hiddenSM}>
                    <Center p={0}>
                        <Group p={0} pb={20} spacing={50}>
                            <Stack justify="flex-start" align="center">
                                <Rating value={5} fractions={2} readOnly   size="sm"/>
                                <Text className={classes.ratingBase}>
                                    "...extremely fast!"
                                </Text>
                            </Stack>
                            <Stack justify="flex-start" align="center">
                                <Rating value={5} fractions={2} readOnly  size="sm"/>
                                <Text className={classes.ratingBase}>
                                    "most usefull tool i've ever used"
                                </Text>
                            </Stack>
                            <Stack justify="flex-start" align="center">
                                <Rating value={5} fractions={2} readOnly  size="sm"/>
                                <Text className={classes.ratingBase}>
                                    "automate anything!"
                                </Text>
                            </Stack>
                        </Group>
                    </Center>
                </Container>

                <Container p={0} size={1100}>
                    <Center>
                        <Text className={classes.description}>Build Data Extraction and Monitoring bots, Automate Web and Desktop Applications, Streamline your Data Transfer or Manual Entry processes.
                        </Text>
                    </Center>
                </Container>
                
                <Container p={0} size={900} className={classes.subDescription}>
                    <Text size="lg" >
                        <Button size="lg" radius="md" m="md" onClick={() => {window.location.href = "https://calendly.com/robomotion-sales/demo"}} variant="outline" color="dark">
                            Get a demo
                        </Button>

                        <Button rightIcon={<IconArrowRight />} size="lg" radius="md" m="md" px={50} onClick={() => {window.location.href = "https://app.robomotion.io/create"}} variant="gradient" gradient={{ from: colors.Primary, to: colors.Primary }}>
                            Try for Free
                        </Button>
                    </Text>
                </Container>

                
                <Container p={60} size={600} pb={100}>
                    <Center>
                        <Image src={"./images/home/Robots.png"}/>
                    </Center>
                </Container>

            </div>
        </Container>

    );
}

export default Hero;
